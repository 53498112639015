import { ComponentStyleConfig } from '@chakra-ui/react';

const baseStyle = {
  default: {
    bgColor: 'background.default',
    borderWidth: '1px',
    borderColor: 'border.default',
    borderRadius: 'base',
    outline: '2px transparent',
    color: 'text.default',
    '::placeholder': {
      color: 'text.placeholder',
    },
  },
  contrast: {
    bgColor: 'background.contrast',
    borderWidth: '1px',
    borderColor: 'border.contrast',
    borderRadius: 'base',
    color: 'text.onContrast',
    '::placeholder': {
      color: 'text.placeholderOnContrast',
    },
  },
};

const invalidStyle = {
  default: {
    borderColor: 'border.danger',
    borderWidth: '1px',
    boxShadow: '0 0 0 1px var(--chakra-colors-border-danger)',
  },
  contrast: {
    borderColor: 'border.dangerContrast',
    boxShadow: '0 0 0 1px var(--chakra-colors-border-danger-contrast)',
  },
};

const disabledStyle = {
  default: {
    bgColor: 'border.default',
    borderColor: 'border.default',
    opacity: 0.6,
    '::placeholder': {
      color: 'text.placeholder',
      opacity: 1,
    },
  },
  contrast: {
    bgColor: 'border.contrast',
    borderColor: 'border.contrast',
    opacity: 0.6,
    '::placeholder': {
      color: 'text.placeholderContrast',
      opacity: 1,
    },
  },
};

const focusStyle = {
  default: {
    borderColor: 'border.focus',
    borderWidth: '1px',
    boxShadow: '0 0 0 1px var(--chakra-colors-border-focus) !important',
    _hover: {
      boxShadow: '0 0 0 1px var(--chakra-colors-border-focus)',
      borderColor: 'border.focus',
    },
  },
  contrast: {
    borderColor: 'border.focusContrast',
    borderWidth: '1px',
    boxShadow: '0 0 0 1px var(--chakra-colors-border-focusContrast) !important',
    _hover: {
      boxShadow: '0 0 0 1px var(--chakra-colors-border-focusContrast)',
      borderColor: 'border.focusContrast',
    },
  },
};

const hoverStyle = {
  default: {
    borderColor: 'border.strong',
  },
  contrast: {
    borderColor: 'border.strongContrast',
  },
};

const defaultStyleObj = (variant: 'default' | 'contrast'): any => {
  return {
    ...baseStyle[variant],
    _hover: {
      ...hoverStyle[variant],
    },
    _disabled: {
      ...disabledStyle[variant],
    },
    _focus: {
      ...focusStyle[variant],
    },
    _invalid: {
      ...invalidStyle[variant],
    },
  };
};
export const FormControlConfig: ComponentStyleConfig = {
  variants: {
    // textarea doesn't have a "field" prop, so its styles live a level up from the others
    default: {
      ...defaultStyleObj('default'),
      field: defaultStyleObj('default'),
    },
    onContrast: {
      // textarea doesn't have a "field" prop, so its styles live a level up from the others
      ...defaultStyleObj('contrast'),
      field: defaultStyleObj('contrast'),
    },
  },
  sizes: {
    xs: {
      fontSize: 'xs',
      minH: 24,
    },
    sm: {
      fontSize: 'sm',
      minH: 32,
    },
    md: {
      fontSize: 'md',
      minH: 40,
    },
    lg: {
      fontSize: 'lg',
      minH: 48,
    },
  },
};
